import moment from "moment-timezone";
import React, { FC, memo } from "react";

import { WorkOrder } from "@/types";
import { setSidePanelOrder } from "@/zustand/useOrderStore";

import styles from "./index.module.scss";
import buttons from "@/style/buttons.module.scss";
import { useSettingsStore } from "@/zustand/useSettingsStore";

type Props = {
  data: WorkOrder;
};
const ListItem: FC<Props> = ({ data }) => {
  const setSelectedDate = useSettingsStore((state) => state.setSelectedDate);
  const setOrderDetailsData = () => {
    // @TODO: handle the case when a work order not present in cache is clicked.
    // Or, if you search for a work order, attached to a vessel.
    setSelectedDate(moment(data.startDate || data.plannedStartDate).toDate());
    setSidePanelOrder(data, true);
  };

  return (
    <div key={data.id} className={styles.listItem}>
      <div className={styles.orderInfoKeys}>
        <span>Customer name:</span>
        <span>Order name:</span>
        <span>Order number:</span>
        <span>Order Type:</span>
        <span>Order Date:</span>
        <span>Status:</span>
      </div>
      <div className={styles.orderInfoValues}>
        <span>{data.customerName}</span>
        <span>{data.name !== "null" ? data.name : data.description}</span>
        <span>{data.orderNumber}</span>
        <span>{data.workOrderType?.name}</span>
        <span>{moment(data.orderDate).format("DD-MM-YYYY")}</span>
        <span>{data.derivedStatus}</span>
      </div>
      <div>
        <button
          className={buttons.modalButton}
          type="button"
          onClick={setOrderDetailsData}
        >
          View details
        </button>
      </div>
    </div>
  );
};
export default memo(ListItem);
