import { gql } from "@apollo/client";

export const UPDATE_VESSEL_NOTE = gql`
  mutation updateVesselNote(
    $facilityId: String!
    $issuedBy: String!
    $vesselNoteId: String!
    $text: String!
  ) {
    updateVesselNote(
      facilityId: $facilityId
      issuedBy: $issuedBy
      vesselNoteId: $vesselNoteId
      text: $text
    )
  }
`;

export const CREATE_VESSEL_NOTE = gql`
  mutation createVesselNote(
    $facilityId: String!
    $issuedBy: String!
    $vesselId: String!
    $text: String!
  ) {
    upsertVesselNote(
      description: ""
      facilityId: $facilityId
      issuedBy: $issuedBy
      text: $text
      vesselId: $vesselId
    )
  }
`;

export const GET_VESSEL_NOTES = gql`
  query getNotes($vesselId: String!) {
    vesselWorkOrder(vesselId: $vesselId) {
      vesselNotes {
        description
        entryDate
        id
        lastModified
        noteType
        text
        textIdentity
        modifiedBy
      }
    }
  }
`;

export const UPDATE_WORK_ORDER_NOTE = gql`
  mutation updateWorkOrderNote(
    $facilityId: String!
    $issuedBy: String!
    $orderNoteId: String!
    $text: String!
  ) {
    updateWorkOrderNote(
      facilityId: $facilityId
      issuedBy: $issuedBy
      orderNoteId: $orderNoteId
      text: $text
    )
  }
`;

export const CREATE_WORK_ORDER_NOTE = gql`
  mutation createWorkOrderNote(
    $facilityId: String!
    $issuedBy: String!
    $workOrderId: String!
    $text: String!
  ) {
    upsertWorkOrderNote(
      description: "Comment"
      facilityId: $facilityId
      issuedBy: $issuedBy
      text: $text
      workOrderId: $workOrderId
    )
  }
`;

export const GET_WORK_ORDER_NOTES = gql`
  query getNotes($orderNumber: String!) {
    workOrderNotes(orderNumber: $orderNumber) {
      description
      entryDate
      id
      lastModified
      noteType
      text
      textIdentity
      modifiedBy
      specifyDescription
    }
  }
`;
